import { Fragment, useRef } from 'react';
import { InputField, ModalPortal, Socials } from 'src/components';
import ContactUsModalSuccessScreen from './contact-us-modal-success';
import styled from 'styled-components/macro';
import { ReactComponent as CloseCross } from 'src/assets/images/close-cross.svg';
import useLockBodyScroll from 'src/hooks/use-lock-bodyscroll';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useForm, Controller } from 'react-hook-form';
import { emailValidation } from './validation';
import { media } from 'src/assets/style/media';
import { useState } from 'react';

const NAME = 'contact_us_name';
const EMAIL = 'contact_us_email';
const PHONE = 'contact_us_phone';
const DETAILS = 'contact_us_details';

const ContactUsModal = ({ closeModal, className }) => {
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  const [error, setError] = useState(false);

  const getFromStorage = (field, value = '') => {
    const storageValue = window.localStorage.getItem(field) || '';
    return storageValue ? storageValue : value;
  };

  const defaultValues = {
    name: getFromStorage(NAME),
    email: getFromStorage(EMAIL),
    phone: getFromStorage(PHONE),
    text: getFromStorage(DETAILS),
  };

  const { control, handleSubmit } = useForm({ defaultValues });

  const onSubmit = async (data) => {
    const url = 'https://bcpromo.online/api/request';
    data.form_id = 'main';
    data.domain = 'https://bcpromo.online';

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(data).toString(),
    });

    if (response.ok) {
      setShowSuccessScreen(true);
      clearLocalStorage();
    } else {
      setError(true);
      setShowSuccessScreen(true);
    }
  };

  const modalRef = useRef(null);

  const clickOutside = (event) => {
    const el = modalRef?.current;
    if (el && !el.contains(event.target)) {
      closeModal();
    }
  };

  const clearLocalStorage = () => {
    window.localStorage.clear();
  };

  const handleInputChange = (field, onChange, e) => {
    const value = field === PHONE ? e : e.target.value;
    window.localStorage.setItem(field, value);
    onChange(e);
  };

  const isMobile = /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );

  useLockBodyScroll();

  return (
    <ModalPortal>
      <ModalLayout onClick={clickOutside} className={className}>
        <ModalBody ref={modalRef}>
          <CloseButton onClick={closeModal}>
            <CloseCross />
          </CloseButton>
          {showSuccessScreen ? (
            <SuccessScreenLayout>
              <ContactUsModalSuccessScreen error={error} />
              <SocialLinks>
                <SocialHeding>STAY IN TOUCH</SocialHeding>
                <StyledSocials gap={20} iconSize={40} />
              </SocialLinks>
            </SuccessScreenLayout>
          ) : (
            <Fragment>
              <InfoSide>
                <Text>
                  connect with us if you want to develop thoughtful product
                </Text>
                <AlignmentContainer>
                  <SocialLinks>
                    <SocialHeding>STAY IN TOUCH</SocialHeding>
                    <StyledSocials gap={20} iconSize={40} />
                  </SocialLinks>
                  <ContactsContainer>
                    <address>ARMENIA, YEREVAN</address>
                  </ContactsContainer>
                </AlignmentContainer>
              </InfoSide>
              <FormSide onSubmit={handleSubmit((data) => onSubmit(data))}>
                <FormTitle>Contact us</FormTitle>
                <Controller
                  name='name'
                  control={control}
                  rules={{
                    required: { value: true, message: 'Required field' },
                    maxLength: {
                      value: 30,
                      message: 'Name shoul  d be less than 30 symbols',
                    },
                  }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <StyledInput
                      label='Your name'
                      name='name'
                      value={value}
                      onChange={(e) => handleInputChange(NAME, onChange, e)}
                      maxlength={30}
                      error={error}
                    />
                  )}
                />
                <Controller
                  name='email'
                  control={control}
                  rules={{
                    required: { value: true, message: 'Required field' },
                    validate: emailValidation,
                  }}
                  render={({
                    field: { value = getFromStorage(EMAIL), onChange },
                    fieldState: { error },
                  }) => (
                    <StyledInput
                      label='Email'
                      name='email'
                      value={value}
                      onChange={(e) => handleInputChange(EMAIL, onChange, e)}
                      error={error}
                    />
                  )}
                />
                <Controller
                  name='phone'
                  control={control}
                  rules={{
                    required: { value: true, message: 'Required field' },
                    validate: (value) => {
                      return value.toString().length >= 5 || 'Invalid phone';
                    },
                  }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <PhoneInputContainer>
                        <PhoneInput
                          isValid={!error?.message.length}
                          countryCodeEditable={false}
                          country={'us'}
                          value={value}
                          onChange={(e) =>
                            handleInputChange(PHONE, onChange, e)
                          }
                        />
                        {error && (
                          <PhoneErrorMessage>{error.message}</PhoneErrorMessage>
                        )}
                      </PhoneInputContainer>
                    );
                  }}
                />
                <Controller
                  name='text'
                  control={control}
                  rules={{
                    maxLength: {
                      value: 1000,
                      message:
                        'Details field should contain less than 1000 symbols',
                    },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <StyledTextArea
                      variant='textarea'
                      label='Project details (optional)'
                      name='text'
                      value={value}
                      onChange={(e) => handleInputChange(DETAILS, onChange, e)}
                      maxlength={1000}
                    />
                  )}
                />

                <MobileInfoLayout>
                  {isMobile && (
                    <SocialLinks>
                      <SocialHeding>STAY IN TOUCH</SocialHeding>
                      <StyledSocials gap={20} iconSize={40} />
                    </SocialLinks>
                  )}
                  <SendButton type='submit'>
                    <SendButtonText>SEND</SendButtonText>
                  </SendButton>
                </MobileInfoLayout>
              </FormSide>
            </Fragment>
          )}
        </ModalBody>
      </ModalLayout>
    </ModalPortal>
  );
};

const PhoneInputContainer = styled.div`
  margin-bottom: 13px;
`;

const PhoneErrorMessage = styled.p`
  color: var(--red);
  font-size: 15px;
  margin-bottom: 8px;
  font-family: 'Helvetica Neue', sans-serif;
`;

const SuccessScreenLayout = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

const MobileInfoLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ModalLayout = styled.div`
  position: fixed;
  width: 100vw;
  height: 100%;
  display: flex;
  background: rgba(0, 0, 0, 0.8);
  z-index: 100;
  top: 0;
  left: 0;
  font-family: 'gyre heros', sans-serif;
  overflow: scroll;
  padding: 150px;
  @media ${media.laptopL()} {
    padding: 80px;
  }
  @media ${media.laptopS()} {
    padding: 50px;
  }
  @media ${media.tabletL()} {
    padding: 0;
  }
`;

const ModalBody = styled.div`
  max-width: 100%;
  border: 4px solid var(--green-main);
  border-radius: 20px;
  margin: auto;
  background: #090e05;
  backdrop-filter: blur(10px);
  padding: 40px 80px;
  color: var(--white);
  display: flex;
  font-size: 2.5vw;
  position: relative;
  box-shadow: inset -5px -5px 10px rgba(255, 255, 255, 0.1),
    inset 5px 5px 10px rgba(255, 255, 255, 0.1);
  @media ${media.laptopS()} {
    flex-direction: column-reverse;
    font-size: 4.8vw;
  }
  @media ${media.tabletL()} {
    padding: 20px;
    border: 0;
    border-radius: 0;
  }
  @media ${media.mobile()} {
    position: fixed;
    width: 100%;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  left: 100%;
  bottom: 100%;
  padding: 5px;
  border-radius: 50%;
  transition: opacity 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }

  &:active {
    opacity: 0.8;
  }

  @media ${media.tabletL()} {
    left: unset;
    bottom: unset;
    top: 5px;
    right: 5px;
  }
`;

const InfoSide = styled.div`
  width: 40%;
  @media ${media.laptopS()} {
    width: 100%;
  }
  @media ${media.mobile()} {
    display: none;
  }
`;

const Text = styled.p`
  text-transform: uppercase;
  margin-bottom: 30px;
  line-height: 1.1;
`;

const AlignmentContainer = styled.div`
  width: min-content;
  @media ${media.mobile()} {
    width: 100%;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  @media ${media.mobile()} {
    flex-direction: column;
  }
`;

const SocialHeding = styled.p`
  margin-right: 20px;
  font-size: 1.25vw;
  white-space: nowrap;
  @media ${media.laptopS()} {
    font-size: 4vw;
  }
  @media ${media.mobile()} {
    margin-right: 0;
    margin-bottom: 15px;
  }
`;

const ContactsContainer = styled.div`
  text-transform: uppercase;
  font-size: 1.25vw;
  text-align: right;

  a {
    display: block;
  }

  @media ${media.laptopS()} {
    display: none;
  }
`;

const FormSide = styled.form`
  width: 60%;
  margin-left: 30px;
  font-size: 24px;

  .react-tel-input {
    position: relative;

    :before {
      content: 'Phone number';
      color: var(--green-inactive);
      font-size: 15px;
      position: absolute;
      top: -7px;
      left: 0.5rem;
      background-color: var(--modal-background);
      padding: 0 8px;
      z-index: 1;

      @media ${media.laptopS} {
        font-size: 13px;
        left: 0.4rem;
      }
    }
  }

  .form-control {
    width: 100%;
    border-color: var(--green-inactive);
    border-radius: 0;
    color: var(--white);
    background-color: transparent;
    box-sizing: border-box;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 70px;
    line-height: 33px;
    height: unset;
    font-size: 24px;
    transition: all 0.4s ease-in-out;

    :focus {
      border-color: var(--green-main);
    }
  }

  .form-control.invalid-number {
    border-left-color: rgba(0, 0, 0, 0);
    border: 1px solid var(--red);
    background-color: rgba(0, 0, 0, 0);

    &:focus {
      border-left-color: rgba(0, 0, 0, 0);
      border: 1px solid var(--red);
      background-color: rgba(0, 0, 0, 0);
    }
  }

  .form-control.open {
    border-color: var(--green-main);
  }

  .flag-dropdown {
    background-color: transparent;
    border: 0;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .selected-flag:hover,
  .selected-flag:focus,
  .flag-dropdown.open {
    background: transparent !important;

    .selected-flag {
      background: transparent;
    }
  }

  .flag-dropdown:after {
    content: '';
    position: absolute;
    left: 100%;
    top: 3px;
    bottom: 3px;
    width: 1px;
    background: linear-gradient(
      180deg,
      rgba(103, 194, 30, 0) 1.08%,
      #67c21e 25.87%,
      #67c21e 52.95%,
      #67c21e 73.78%,
      rgba(103, 194, 30, 0) 101.08%
    );
  }

  .country-list {
    top: 100%;
    left: 0;
    background-color: var(--modal-background);
    border: 1px solid var(--green-main);
    margin-top: 0;

    .country:hover {
      background-color: var(--green-hover-menu);
    }

    .country.highlight {
      background-color: var(--green-hover-menu);
    }
  }

  @media ${media.laptopS()} {
    width: 100%;
    margin-left: 0;
    font-size: 18px;
    .form-control {
      font-size: 18px;
    }
  }
`;

const FormTitle = styled.h4`
  text-transform: uppercase;
  border-bottom: 2px solid var(--green-main);
  width: min-content;
  white-space: nowrap;
  font-size: 14px;
  margin-bottom: 30px;
`;

const StyledInput = styled(InputField)`
  margin-bottom: 13px;
  font-family: 'Helvetica Neue', sans-serif;
`;

const StyledTextArea = styled(InputField)`
  width: 100%;
  margin-bottom: 30px;
  font-family: 'Helvetica Neue', sans-serif;

  @media ${media.mobile()} {
    max-height: 100px;
    height: 150px;
  }
`;

const SendButton = styled.button`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 1px solid var(--green-main);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: var(--white);
  transition: all 0.4s ease-in-out;
  margin-left: auto;
  cursor: pointer;

  @media ${media.mobile()} {
    width: 80px;
    height: 80px;
  }

  :hover {
    transform: scale(0.9);
    border: 2px solid var(--green-hover);

    div {
      font-size: 15.4px;
    }

    div:after {
      height: 8px;
      background-color: var(--green-hover);
    }
  }

  @media ${media.laptopS()} {
    margin-bottom: 40px;
  }
`;

const SendButtonText = styled.div`
  position: relative;

  :after {
    content: '';
    width: 100%;
    position: absolute;
    top: 100%;
    background-color: var(--green-main);
    height: 2px;
    left: 0;
    transition: all 0.4s ease-in-out;
  }
`;

const StyledSocials = styled(Socials)`
  @media ${media.laptopM()} {
    gap: 10px;
    svg {
      width: 30px;
      height: 30px;
    }
  }
  @media ${media.laptopS()} {
    gap: 20px;
    svg {
      width: 40px;
      height: 40px;
    }
  }

  @media ${media.mobile()} {
    gap: 10px;
    svg {
      width: 30px;
      height: 30px;
    }
  }
`;

export default ContactUsModal;
